export type State = {
  // todo
}

export const initialState: State = {}

export type Action = { readonly type: string } // define more when non-empty

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    default:
      throw Error('Unexpected action')
  }
}
