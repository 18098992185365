import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faBars,
  faMicrophoneAlt,
  faSearch,
  faPlay,
  faBroadcastTower,
  faPlus,
  faSignInAlt
} from '@fortawesome/free-solid-svg-icons'
import {
  faPlayCircle,
  faUserCircle,
  faCheckCircle,
  faThumbsUp,
  faThumbsDown
} from '@fortawesome/free-regular-svg-icons'
// import { } from '@fortawesome/free-brands-svg-icons'

export default function fontawesomeLibrary() {
  library.reset()

  // solid
  library.add(
    faBars,
    faMicrophoneAlt,
    faSearch,
    faPlay,
    faBroadcastTower,
    faPlus,
    faSignInAlt
  )

  // regular (free)
  library.add(faPlayCircle, faUserCircle, faCheckCircle, faThumbsUp, faThumbsDown)

  // brands
  library.add()
}
