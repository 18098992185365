import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import AsyncLoad from '../../shared/components/async-load/async-load'

// routes
const NotFound = AsyncLoad(() => import('../../shared/pages/not-found/not-found'))
const Home = AsyncLoad(() => import('../pages/home/home'))
const Login = AsyncLoad(() => import('../pages/login/login'))
const Account = AsyncLoad(() => import('../pages/account/account'))
const Discover = AsyncLoad(() => import('../pages/discover/discover'))
const Artists = AsyncLoad(() => import('../pages/artists/artists'))
const ArtistMe = AsyncLoad(() => import('../pages/artist-me/artist-me'))
const ArtistId = AsyncLoad(() => import('../pages/artist-id/artist-id'))

export default function LayoutDefaultRoutes({ prefix: p }: { prefix: string }) {
  return (
    <Switch>
      <Redirect exact path={`${p}home`} to={`${p}`} />

      <Route exact path={`${p}`} component={Home} />
      <Route exact path={`${p}login`} component={Login} />
      <Route exact path={`${p}account`} component={Account} />
      <Route exact path={`${p}discover`} component={Discover} />
      <Route exact path={`${p}artists`} component={Artists} />
      <Route exact path={`${p}artist/me`} component={ArtistMe} />
      <Route
        exact
        path={`${p}artist/:_id`}
        render={props => <ArtistId {...props} _id={props.match.params['_id']} />}
      />

      <Route component={NotFound} />
    </Switch>
  )
}
