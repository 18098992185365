import { INavigationItem } from '../../shared/components/layout/header/interfaces'
import _ from '../../lib/language'

export default function layoutDefaultNavigation(isLoggedIn: boolean): INavigationItem[] {
  let items: INavigationItem[] = [
    {
      to: '/discover',
      icon: ['far', 'play-circle'],
      name: _`Discover Music`,
      shortName: _`Discover`
    }
  ]

  if (isLoggedIn) {
    items = items.concat([
      {
        to: '/artists',
        icon: ['fas', 'microphone-alt'],
        name: _`Artists`,
        shortName: _`Artists`
      },
      {
        to: '/account',
        icon: ['far', 'user-circle'],
        name: _`Account Settings`,
        shortName: ' '
      }
    ])
  } else {
    items = items.concat([
      {
        to: '/login',
        icon: 'sign-in-alt',
        name: _`Login or Register`,
        shortName: _`Login`
      }
    ])
  }

  return items
}
